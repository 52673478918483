













import { Component, Vue } from "vue-property-decorator";
import { Tab, Tabs } from "vant";
import DeliveryList from "@/views/mine/components/DeliveryList.vue";

@Component({
  components: {
    DeliveryList,
    Tabs,
    Tab,
  },
})
export default class MyDeliveryRecord extends Vue {}
