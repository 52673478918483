


















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { TYPE_TEXT } from "@/constant";

@Component({})
export default class MySupplyItem extends Vue {
  @Prop({ default: 1 }) private readonly character!: number;
  @Prop({ default: "" }) private readonly title!: string;
  @Prop({ default: "" }) private readonly cover!: string;
  @Prop({ default: false }) private readonly isEntity!: boolean | string;
  @Prop({ default: "107.5px" }) private readonly wideHigh!: string;

  @Emit("click")
  handleClick(): void {
    return;
  }

  get entity(): boolean {
    return this.isEntity !== false;
  }

  get characterText(): string {
    const { character } = this;
    return TYPE_TEXT[character] ?? "";
  }

  get rootStyle(): Record<string, string> {
    const { character, wideHigh } = this;
    return {
      "--characterColor": `var(--card-color-${character})`,
      "--where": wideHigh,
    };
  }
}
