






















import { Component, Prop, Vue } from "vue-property-decorator";
import MySupplyItem from "@/components/MySupplyItem.vue";
import { Icon } from "vant";
import { Deliver } from "@/api/blind-box.api";
import InteractionLib from "@/utils/interaction.lib";
import { getNow } from "@/utils/common.util";

@Component({
  components: { MySupplyItem, Icon },
})
export default class DeliveryItem extends Vue {
  @Prop() private readonly item!: Deliver;
  toMyDeliveryRecordDetail() {
     if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/my-delivery-record/${this.item.id}`);
    }
  
    this.$router.push({
      name: "MyDeliveryRecordDetail",
      params: {  id: String(this.item.id) },
    });
  }
}
