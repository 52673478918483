import { render, staticRenderFns } from "./DeliveryItem.vue?vue&type=template&id=7bdd6e02&scoped=true&"
import script from "./DeliveryItem.vue?vue&type=script&lang=ts&"
export * from "./DeliveryItem.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryItem.vue?vue&type=style&index=0&id=7bdd6e02&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bdd6e02",
  null
  
)

export default component.exports